import React from "react"
import BlockContent from "@sanity/block-content-to-react"
import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby"
import PageTitle from "../components/dynamic-components/page-title"
import SectionColumns from "../components/elements/section-columns"
import NewsLetterBox from "../components/elements/news-letter-box"
import SEO from "../components/seo"

const NewsletterPage = ({ location }) => {
  const { sanitySiteInfo } = useStaticQuery(graphql`
    query {
      sanitySiteInfo(site_id: { current: { eq: "veracom" } }) {
        newsletter_configuration {
          page_title
          page_description {
            _rawContent
            _key
            _type
          }
        }
      }
    }
  `)
  const title =
    sanitySiteInfo &&
    sanitySiteInfo.newsletter_configuration &&
    sanitySiteInfo.newsletter_configuration.page_title

  const content =
    sanitySiteInfo &&
    sanitySiteInfo.newsletter_configuration &&
    sanitySiteInfo.newsletter_configuration.page_description

  return (
    <Layout>
      <SEO title={title ? title : ""} url={location.pathname} />
      <PageTitle page_title_header={title ? title : ""} />
      <SectionColumns isCentered isMultiline>
        <div className="column is-8 rich-text">
          {content &&
            content.map(c => (
              <BlockContent key={c._key} blocks={c._rawContent} />
            ))}
        </div>
        <div className="column is-8">
          <NewsLetterBox />
        </div>
      </SectionColumns>
    </Layout>
  )
}

export default NewsletterPage
